import styled from 'styled-components';

export const MapBody = styled.div`
    position: relative;
    width: 100%;
    .leaflet-container {
        height: 300px;
        margin: 0 auto;
      }
`;
