import React from "react";
import {
  Container,
  ContactBox,
  LeftArea,
  RightArea,
  TextArea,
  InputTextField,
  SubmitButton,
  ContactInfoContainer,
} from "./contact.styles";

export const Contact = () => {
  return (
    <>
      <Container>
        <ContactBox>
          <LeftArea>
            <ContactInfoContainer>
              <h2>Caballito</h2>
              <p>Av. San Martín 1802</p>
              <h2>Llamanos</h2>
              <p>(011) 4581 - 0047</p>
              <h2>Whatsapp</h2>
              <p>+54 9 11 3385 - 7535</p>
              <h2>Escribinos</h2>
              <p>info@konpropiedades.com.ar</p>
            </ContactInfoContainer>
          </LeftArea>
          <RightArea>
            <h2>¡Vamos a ponernos en contacto!</h2>
            <form action="https://submit-form.com/wEids20X">
              <input type="hidden" name="_redirect" value="https://konpropiedades.com.ar/" />
              <InputTextField
                name="Nombre"
                type="text"
                class="field"
                placeholder="Nombre"
                required
              />
              <InputTextField
                name="Apellido"
                type="text"
                class="field"
                placeholder="Apellido"
                required
              />
              <InputTextField
                name="Direccion de mail"
                type="email"
                class="field"
                placeholder="Direccion de mail"
                required
              />
              <InputTextField
                name="Telefono"
                type="text"
                class="field"
                placeholder="Numero de contacto"
                required
              />
              <TextArea name="Mensaje" />
              <SubmitButton type="submit">Enviar</SubmitButton>
            </form>
          </RightArea>
        </ContactBox>
      </Container>
    </>
  );
};

export default Contact;
