import React from "react";
import {
  ContactXsContainer,
  InputTextField,
  Submit,
  Form,
} from "./contactXs.styles";
import Space from "../../components/space/space";
export const ContactXs = ({propertyName}) => {
  return (
    <>
      <ContactXsContainer>
        <h3>Programemos una visita!</h3>
        <Form action="https://submit-form.com/wEids20X"> 
        <input type="hidden" name="_redirect" value="https://konpropiedades.com.ar/" />
        <InputTextField
            name="Propiedad"
            type="hidden"
            class="field"
            value={propertyName}
            disabled
          />
          <InputTextField
            name="Nombre y Apellido"
            type="text"
            class="field"
            placeholder="Nombre y Apellido"
            required
          />
          <Space vertical double />
          <InputTextField
            name="email"
            type="email"
            class="field"
            placeholder="ejemplo@ejemplo.com"
            required
          />
          <Space vertical double />
          <InputTextField
            name="Numero de contacto"
            type="text"
            class="field"
            placeholder="Numero de contacto"
            required
          />
          <Space vertical double />
          <Submit type="submit">Enviar</Submit>
        </Form>
      </ContactXsContainer>
    </>
  );
};
